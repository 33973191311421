import React from 'react';
import { price } from 'utilities';

export const TotalPrice = (total, itemFee) => {
    // const { total, itemFee } = props;

    if (itemFee !== 0) {
        return price(total + itemFee)
    } else {
        return price(total);
    }
};
