import React from 'react';
import DOMPurify from 'dompurify';
import { BCA_DD, BNI_DD, BRI_DD, MANDIRI_DD, price } from 'utilities';
import { BoxInfo } from 'presentations/shared';

export const Description = (props) => {
    const { items, isDisable, isChecked } = props;
    const sanitizer = DOMPurify.sanitize;
    if (items.description && items.description !== '' && items.description !== 'null') {
        return (
            <div
                className={` Description text-desc text-gray-desc pl-6 pr-4 ml-3  lg:ml-5 font-face-poppinsregular  ${isDisable()} ${
                    isChecked ? ' mb-2' : 'max-h-7  mb-2 mr-2'
                }`}
            >
                <div className="Description" dangerouslySetInnerHTML={{ __html: sanitizer(items.description) }} />
            </div>
        );
    }
    return null;
};

export const PartnerDisplayName = (props) => {
    const { items, isDisable } = props;

    if (items.bind_info && items.bind_info.status === 'binding') {
        return <BindingPartner items={items} isDisable={isDisable} />;
    } else {
        return (
            // show normal payment partner
            <p className={`text-partner font-face-poppinsbold ml-3 lg:ml-5 text-blue-dark_blue ${isDisable()}`}>
                {items.display_name}
                {items.bank_display_name}
            </p>
        );
    }
};

export const BindingPartner = (props) => {
    const { items, isDisable } = props;
    if (items.partner_provider === MANDIRI_DD) {
        return (
            // show masked number for already binding mandiri account
            <div>
                <p className={`text-partner font-face-poppinsbold ml-3 lg:ml-5 text-blue-dark_blue ${isDisable()}`}>
                    {items.display_name}
                </p>
                <p className={`text-desc text-gray-desc ml-3 lg:ml-5 font-face-poppinsregular mt-1 ${isDisable()}`}>
                    {`**** **** **** ${items.bind_info.token_info.maskedCardNumber}`}
                </p>
            </div>
        );
    }
    if (items.partner_provider === BRI_DD || items.partner_provider === BNI_DD || items.partner_provider === BCA_DD) {
        return (
            // show masked number for already binding mandiri account
            <div>
                <p className={`text-partner font-face-poppinsbold ml-3 lg:ml-5 text-blue-dark_blue ${isDisable()}`}>
                    {items.display_name}
                </p>
                <p className={`text-desc text-gray-desc ml-3 lg:ml-5 font-face-poppinsregular mt-1 ${isDisable()}`}>
                    {`**** **** **** ${items.bind_info.token_info.maskedCardNumber}`}
                </p>
            </div>
        );
    } else {
        return (
            // show balance for already binding gopay or dana account
            <div className="pl-3 lg:pl-5 flex flex-col">
                <p className={`text-partner font-face-poppinsbold text-blue-dark_blue ${isDisable()}`}>
                    {items.display_name}
                </p>
                <p className={`text-partner font-face-poppinsregular text-blue-dark_blue ${isDisable()}`}>
                    Saldo Rp{' '}
                    {price(
                        items?.bind_info?.token_info?.balance !== undefined
                            ? items?.bind_info?.token_info?.balance
                            : items?.bind_info?.token_info?.wallet_balance
                    )}
                </p>
            </div>
        );
    }
};

export const BalanceDisplayName = (props) => {
    const { isDisable, balance, balance_nominal } = props;
    return (
        <div className="flex flex-col ml-3 lg:ml-5">
            <span
                className={`text-partner font-face-poppinsbold text-blue-dark_blue ${
                    isDisable() // flag === 2, will make partner item disabled
                }`}
            >
                {balance}
            </span>
            <span className={`text-partner font-face-poppinsregular text-blue-dark_blue ${isDisable()}`}>
                Saldo Rp {balance_nominal}
            </span>
        </div>
    );
};

export const DisabledMessage = (items) => {
    if (isDisabledMessageExist(items)) {
        return (
            <div className="px-8 lg:px-10 mb-3">
                <BoxInfo text={items.description_disabled} />
            </div>
        );
    }
    return null;
};

export const isDisabledMessageExist = (props) => {
    const { items } = props;
    return items.flag && items.flag === 2 && items.description_disabled !== '' && items.is_show_description_disabled;
};

export const UnbindButton = (props) => {
    const { items, isChecked, onUnbind, t } = props;

    return (
        <>
            {items.bind_info && items.bind_info.status === 'binding' && items.cta && items.cta.is_cta && isChecked ? (
                <button className="w-full py-2" onClick={onUnbind}>
                    <p className="font-face-poppinsbold text-xs text-blue-link text-center">
                        {items.cta.description_cta && items.cta.description_cta !== 'null'
                            ? items.cta.description_cta
                            : t('unbind_account')}
                    </p>
                </button>
            ) : null}
        </>
    );
};
