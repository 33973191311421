import { handleSelect } from "utilities";

// pricing based on list_payment_partner params
export function setPricing(partner, body, external_payment, list_payment_partner) {
    // set item_price as value
    let total = body.item_details.item_price;
    const payment_provider = handleSelect(partner);

    const externalPaymentPricing = () => {
        let partnerListExternal = external_payment.filter((word) => word.partner_provider === payment_provider.name);
        if (partnerListExternal.length !== 0) {
            if (partnerListExternal[0]?.item_fee && partnerListExternal[0]?.item_fee !== 0) {
                total = total + partnerListExternal[0].item_fee;
            }
        }
    };

    const partnerPricing = () => {
        let partnerList = list_payment_partner.filter((word) => word.partner_provider === payment_provider.name);
        if(payment_provider.provider !== null) {
            partnerList = list_payment_partner.filter((word) => (word.bank_name === payment_provider.name && word.partner_provider === payment_provider.provider));
        }
        if (partnerList[0]?.item_fee && partnerList[0]?.item_fee !== 0) {
            total = total + partnerList[0].item_fee;
        }
    };

    const virtualAccountPricing = () => {
        let bankList = body.list_bank.filter((word) => word.bank_name === payment_provider.name);
        if (bankList[0]?.item_fee && bankList[0]?.item_fee !== 0) {
            total = total + bankList[0].item_fee;
        }
    };

    const isExternalPartner = () => {
        return (
            external_payment !== null && external_payment.filter((word) => word.partner_provider === payment_provider.name).length > 0
        );
    };

    const isListPaymentPartner = () => {
        return (
            list_payment_partner !== null &&
            ((list_payment_partner.filter((word) => word.partner_provider === payment_provider.name).length > 0) ||
            (list_payment_partner.filter((word) => word.partner_provider === payment_provider.provider).length > 0))
        );
    };

    const isVirtualAccount = () => {
        return body.list_bank && body.list_bank.filter((word) => word.bank_name === payment_provider.name).length > 0;
    };

    if (payment_provider.name !== 'balance') {
        if (isExternalPartner()) {
            // set price value as external partner price value
            externalPaymentPricing();
        } else if (isListPaymentPartner()) {
            // set price value as partner price value
            partnerPricing();
        } else if (isVirtualAccount()) {
            // set price value as list_bank virtual account price value
            virtualAccountPricing();
        }
    }
    return total;
}
