import { AGGREGATOR_PARTNER } from 'utilities';

// code separator for payment code
export const code_separators = (num) => {
    let num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{4})+(?!\d))/g, '-');
    return num_parts.join('.');
};

export const handleSelect = (value) => ({
    name: AGGREGATOR_PARTNER.includes(value.split('|')[0]) ? value.split('|')[1] : value.split('|')[0],
    provider: AGGREGATOR_PARTNER.includes(value.split('|')[0]) ? value.split('|')[0] : null,
});
