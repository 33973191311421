// determine page / url to redirect based on payment partner and response from Elisa

export const redirectPayment = (res, body, navigate, total) => {
    let url = '';
    if (body.redirect_url_back) {
        url = body.redirect_url_back;
    } else {
        url = body.redirect_url_logo;
    }
    const partner = body.payment_info.bank_name ? body.payment_info.bank_name : body.payment_info.payment_provider;
    switch (res.partner_type) {
        case 1:
            window.location.replace(res.payment_url);
            break;
        case 2:
            navigate('/qris', {
                state: {
                    qris_url: res.qr_url,
                    detail: body.item_details,
                    total: total,
                    payment_info: body.payment_info,
                    channel: body.channel,
                    redirect: body.redirect_url,
                    help: body.help_info,
                    failed: body.redirect_url_failed,
                },
            });
            break;
        case 3:
            navigate(`/paymentcode/${partner}`, {
                state: {
                    code: res.payment_code,
                    merchant: partner,
                    total: total,
                    detail: body.item_details,
                    payment_info: body.payment_info,
                    lang: body.lang,
                    app: body.channel,
                    redirect: url,
                    failed: body.redirect_url_failed,
                },
            });
            break;
        case 4:
            break;
        default:
            window.location.replace(body.redirect_url_failed);
            break;
    }
};
