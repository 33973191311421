import { fetchApi } from 'utilities';


// initiate auth binding for direct debit partner
export async function initiateAuthBinding(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/bind/bindingURL', body);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}

// initiate binding for direct debit partner
export async function initiateBinding(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/bind/binding', body);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}

// initiate unbinding for direct debit partner
export async function initiateUnbinding(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/bind/unbinding', body, auth);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}

// check status binding for direct debit partner
export async function checkStatusBinding(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/bind/checkbinding', body);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}

// request otp for direct debit partner
export async function requestOtpCode(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/bind/requestOtpDd', body);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}
