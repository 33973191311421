import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DANA, getParams, SHOPEEPAY } from 'utilities';
import { bindAccount } from 'domain/use-case';
import Cookies from 'js-cookie';

export default function RedirectPageViewModel() {
    let { partner } = useParams();
    let navigate = useNavigate();
    const [params, setParams] = useState({});

    useEffect(() => {
        // get params from url
        setParams(getParams);
    }, [params.channel]);

    let binding_body = {
        payment_provider: partner,
        auth_code: params.auth_code,
    };
    let token = params.token ? params.token : Cookies.get('upp_token');
    const initiateBinding = async () => {
        try {
            const response = await bindAccount(binding_body, token);
            if (response && response.status_code === '00') {
                // navigate to success binding page
                navigate({
                    pathname: '/success_binding',
                    search: `?channel=${params.channel}&partner=${partner}`,
                });
            }
        } catch (error) {
            // get error response from dana binding API
            navigate({
                pathname: '/failed_binding',
                search: `?channel=${params.channel}&partner=${partner}`,
            });
        }
    };

    const redirectPage = useCallback(async () => {
        if (partner === DANA || partner === SHOPEEPAY) {
            // check auth code on url, call binding API for dana
            if (params.auth_code !== null) {
                await initiateBinding();
            } else {
                // auth_code params not found on callback url, redirect to failed binding page
                navigate({
                    pathname: '/failed_binding',
                    search: `?channel=${params.channel}&partner=${partner}`,
                });
            }
        } else if (params.channel) {
            navigate({
                pathname: '/success_binding',
                search: `?channel=${params.channel}&partner=${partner}`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, params.auth_code, params.channel, partner, token]);

    return {
        params,
        redirectPage,
    };
}
